import React from "react"
import { navigate } from "gatsby"
import {  isLoggedIn } from "../services/auth"
import Layout from "../components/layout";
import LoginFormComponent from "../app/LoginFormComponent";

class Login extends React.Component {

    render() {
        if (isLoggedIn()) {
            navigate(`/app/profile`)
        }

        return (
            <Layout>
                <LoginFormComponent />
            </Layout>
        );
    }
}

export default Login
