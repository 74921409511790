import React from "react"
import {Link, navigate} from "gatsby"
import { handleLogin, isLoggedIn } from "../services/auth"
import * as UtilService from '../services/utils.service';

class LoginFormComponent extends React.Component {
    state = {
        user:{
            username: null,
            password: null,
        },
        loading:false,
    }

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
    }

    handleUpdate(event) {
        let userChanges = {
            [event.target.name]: event.target.value,
        };
        userChanges = Object.assign(this.state.user,userChanges,);
        this.setState({user:userChanges})

    }

    handleSubmit(event) {
        event.preventDefault()
        this.setState({loading:true})

        let data = {...this.state.user};

        let isEmail = UtilService.isEmail(this.state.user.username);
        let isCpfCnpj = UtilService.validarCPF(this.state.user.username) || UtilService.validarCNPJ(this.state.user.username);

        if(isEmail || isCpfCnpj){
            if(isEmail){
                data.email = this.state.user.username;
            }else{
                data.cpfCnpj = this.state.user.username;
            }

            handleLogin(data).then(()=>{
                navigate(`/`)
            }).catch(err=>{
                if(err.status && err.json){
                    err.json().then(body=>{

                        if(err.status===401 && body.message){
                            alert(body.message)
                        }
                    })
                }else{
                    alert('Erro ao acessar serviço. Tente mais tarde')

                }
                this.setState({loading:false})

            })
        }else{
            this.setState({loading:false})
            alert('Email,CPF ou CNPJ inválido')
        }

    }

    render() {
        if (isLoggedIn()) {
            navigate(`/app/profile`)
        }

        return (
                <div className="product-area ptb-90">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-4 col-sm-offset-4">
                                <div className="account-form">
                                    <h4 className="text-center mb-30">Entre em sua conta</h4>
                                    <form method="post" onSubmit={event => {
                                                        this.handleSubmit(event)
                                                    }}>
                                        <fieldset disabled={this.state.loading}>
                                            <div className="form-group">
                                                <label className="sr-only">Username</label>
                                                <input type="text" className="form-control input-lg" placeholder="E-mail, CPF ou CNPJ" name="username" onChange={this.handleUpdate}/>
                                            </div>

                                            <div className="form-group">
                                                <label className="sr-only">Password</label>
                                                <input id="password" type="password" className="form-control input-lg" placeholder="senha" name="password" onChange={this.handleUpdate}/>
                                            </div>

                                            <div className="form-group">
                                                <button disabled={!this.state.user.username || !this.state.user.password} type="submit" className="btn btn-block btn-round btn-base" >Entrar</button>
                                            </div>

                                            {this.state.loading?(
                                                    <div className="mt-30 text-center">
                                                        <i className="fa fa-spinner fa-spin fa-2x fa-fw"/>
                                                    </div>
                                                ):
                                                (
                                                    <div className="mt-30 text-center">
                                                        <p>Novo por aqui? <Link to="/registrar">clique e se cadastre!</Link></p>
                                                        <p><Link to="/recuperar-senha" className="text-success">Socorro! esqueci minha senha!!</Link></p>
                                                    </div>
                                                ) }

                                        </fieldset>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}

export default LoginFormComponent
